import React from 'react'

/**
 * Componentes externos utilizados nesta página:
 */
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Search } from '@monorepo-amais/commons/components/Search'
import { graphql } from 'gatsby'
import imgixUrlOptimizerAuto from '@monorepo-amais/commons/utils/imgixUrlOptimizerAuto'

const ResultadoBusca = ({ location, data }) => (
	<>
		<Layout>
			<SEO
				title={data.cosmicjsInformacoesEstaticas.metadata.seo.titulo}
				description={data.cosmicjsInformacoesEstaticas.metadata.seo.descricao}
				image={imgixUrlOptimizerAuto(data.cosmicjsInformacoesEstaticas.metadata.seo.imagem.imgix_url)}
			/>
			<Search location={location} />
		</Layout>
	</>
)

export const query = graphql`
	query {
		cosmicjsInformacoesEstaticas(title: { eq: "Busca" }) {
			metadata {
				seo {
					descricao
					titulo
					imagem {
						imgix_url
						url
					}
				}
			}
		}
	}
`

export default ResultadoBusca
